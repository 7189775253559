import styled from 'styled-components';

import vars from 'variables';

import claimed from 'assets/Claim/claimed.png';
import coins1 from 'assets/Claim/coins1.svg';
import coins2 from 'assets/Claim/coins2.svg';

const Root = styled.div`
  .loading & .header *,
  .loading & .body-width {
    opacity: 0;
  }

  .header {
    margin-top: -120px;
    position: relative;
    background-color: rgb(0, 3, 22);
    background-repeat: no-repeat;
  }

  .page-header h4 {
    font-weight: 500;
  }

  .claimed {
    text-transform: uppercase;
  }

  .claimed div {
    margin: 0 auto;
    position: relative;
    background: url(${claimed}) no-repeat;
    background-size: contain;
  }

  .claimed div strong {
    letter-spacing: 0.5px;
  }

  .claimed div img {
    position: absolute;
  }

  .site-data span {
    color: ${vars.colors.blue};
    text-transform: uppercase;
  }

  .hr {
    margin: 0 auto;
    background: ${vars.colors.blue};
  }

  ul {
    text-align: left;
  }

  ul li {
    background: rgb(14, 22, 44);
  }

  ${vars.desktop.mediaQuery} {
    .header {
      padding-top: 175px;
      background-image: url(${coins1}), url(${coins2});
      background-position: top 100px left, top 200px right;
      background-size: auto, auto;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    .page-header h4 {
      letter-spacing: 2px;
    }

    .page-header h1 {
      font-size: 5.75rem;
    }

    .page-header p {
      font-size: 1rem;
    }

    .claimed {
      padding-top: 50px;
      padding-bottom: 150px;
    }

    .claimed div {
      width: 580px;
      height: 155px;
      margin-top: 20px;
    }

    .claimed div h3 {
      padding-top: 25px;
      font-size: 3.75rem;
      font-weight: 900;
      line-height: 120%;
    }

    .claimed div strong {
      font-size: 1.2rem;
    }

    .claimed div img {
      bottom: -50px;
      left: -100px;
    }

    .claimed p {
      font-size: 1.2rem;
      letter-spacing: 0.5px;
    }

    .site-data {
      margin-top: 90px;
    }

    .site-data span {
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 1.5px;
    }

    .site-data h2 {
      padding: 10px 0 20px;
      font-size: 3.75rem;
    }

    .site-data p {
      padding-bottom: 30px;
      color: ${vars.colors.grey};
      font-size: 1rem;
    }

    button.blue.logo {
      padding-left: 55px;
      padding-right: 24px;
      font-size: 0.9rem;
      letter-spacing: 0.5px;
      background-position: left 23px center;
    }

    .hr {
      width: 30px;
      height: 5px;
      margin: 0 auto;
      margin-top: 110px;
      margin-bottom: 20px;
      background: ${vars.colors.blue};
      border-radius: 3px;
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 600;
    }

    ul {
      margin-top: 40px;
    }

    ul strong {
      font-size: 1.1rem;
    }

    ul p {
      font-size: 1rem;
    }

    .connect-button {
      margin-top: 35px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      padding-top: 150px;
      background-image: url(${coins1}), url(${coins2});
      background-position: top 90px left, top 90px right;
      background-size: 85px, 60px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .page-header h4 {
      font-size: 0.6rem;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    .page-header h1 {
      margin-top: -2px;
      margin-bottom: -3px;
      font-size: 2.1rem;
    }

    .claimed {
      padding-top: 35px;
      padding-bottom: 60px;
    }

    .claimed div {
      width: 320px;
      height: 86px;
      margin-top: 5px;
      border-radius: 12px;
    }

    .claimed div h3 {
      padding-top: 25px;
      padding-bottom: 8px;
      font-size: 1.65rem;
      font-weight: 800;
      letter-spacing: 1px;
    }

    .claimed div strong {
      font-size: 0.75rem;
    }

    .claimed div img {
      width: 60px;
      bottom: -20px;
      left: -10px;
    }

    .claimed p {
      color: #bbb;
      font-size: 0.85rem;
    }

    .site-data {
      margin-top: 45px;
      margin-bottom: 25px;
    }

    .site-data span {
      font-size: 0.65rem;
      font-weight: 600;
    }

    .site-data h2 {
      padding-bottom: 12px;
      font-size: 1.5rem;
    }

    .site-data p {
      padding: 0 20px;
    }

    .hr {
      width: 11px;
      height: 2px;
      margin-top: 60px;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 0.9rem;
    }

    ul {
      margin-top: 20px;
    }

    ul li {
      padding-left: 20px;
      padding-right: 20px;
    }

    .connect-button {
      margin-top: 35px;
    }
  }
`;

export { Root };
