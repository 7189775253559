import React, { useEffect, useRef } from 'react';
import { Root, Message } from './style';

import { useState } from '@hookstate/core';
import usePortal from 'react-useportal';

import Modal from 'components/Common/Modal';

import hodlapp from 'assets/Claim/Connect/hodlapp.svg';
import metamask from 'assets/Claim/Connect/metamask.svg';
import trustwallet from 'assets/Claim/Connect/trustwallet.svg';
import safepal from 'assets/Claim/Connect/safepal.svg';
import wconnect from 'assets/Claim/Connect/walletconnect.svg';

import { useWeb3, INJECTED, WALLETCONNECT } from 'web3';

import vars from 'variables';

const Connect = () => {
  const error = useState('');
  const modal = useRef();

  const { connect, wallet } = useWeb3();

  const { Portal } = usePortal({
    bindTo: document && document.getElementById('menu-buttons'),
  });

  const showModal = () => {
    error.set('');
    modal.current.show();
  };

  const web3Connect = (loginType) => {
    connect(loginType).catch(({ message }) => {
      if (message !== 'closed') {
        error.set(message);
        modal.current.show();
      }
    });
  };

  useEffect(() => {
    if (window.ethereum) {
      web3Connect(INJECTED);
    }
  }, [wallet]);

  return (
    <div>
      <Portal>
        <Root>
          <a className="button connect blue" onClick={showModal}>
            Connect
          </a>
        </Root>
      </Portal>
      <Modal className="scaled-07" usedRef={modal}>
        {error.get() ? (
          <Message className="error">
            <h4>
              <span>Something went wrong</span>
            </h4>
            <h2>Oops! {error.get()}</h2>
            <a
              className="button blue logo"
              href={vars.links.buy}
              target="_blank"
              rel="noreferrer"
            >
              Buy HODL now
            </a>
          </Message>
        ) : (
          <Message>
            <h3>Connect your wallet</h3>
            <ul>
              {window.ethereum?.isHODLApp ? (
                <li onClick={() => web3Connect(INJECTED)}>
                  <strong>HODL App</strong>
                  <img src={hodlapp} />
                </li>
              ) : null}
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>MetaMask</strong>
                <img src={metamask} />
              </li>
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>Trust Wallet</strong>
                <img src={trustwallet} />
              </li>
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>SafePal</strong>
                <img src={safepal} />
              </li>
              <li className="hr">
                <span>or</span>
              </li>
              <li onClick={() => web3Connect(WALLETCONNECT)}>
                <strong>WalletConnect</strong>
                <img src={wconnect} />
              </li>
            </ul>
          </Message>
        )}
      </Modal>
    </div>
  );
};

export default Connect;
